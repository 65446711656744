<template>
  <transition name="toggleContentComponent">
    <article class="gallery" v-if="ready" :class="{'gallery--singleimage': !isGallery}">
      
      <transition-group name="slide"
        :enter-active-class="animations[galleryDirection].enter"
        :leave-active-class="animations[galleryDirection].leave">
          <img v-for="image in computedGallery" :key="image.src" :src="image.src" :alt="image.name">
      </transition-group>

      <transition name="galleryNavTransition">
        <nav class="gallery__nav" v-if="isGallery">
            <button class="gallery__nav_arrow gallery__nav_arrow_prev" @click="prevGallery"></button>
            <button class="gallery__nav_dot" v-for="dot in galleryNavButtons" :key="dot.index" @click="changeGallery(dot.index)" :class="{'gallery__nav_dot--active': dot.index === activeGallery}"></button>
            <button class="gallery__nav_arrow gallery__nav_arrow_next" @click="nextGallery"></button>
        </nav>
      </transition>

      <button class="closebutton" @click="closeComponent" aria-label="luk galleri">
        <img src="@/assets/img/ui/close.png" alt="luk">
      </button>
    </article>
  </transition>
</template>

<script>


export default {
  props: [
    "properties",
    "pageData",
    "slug"
  ],
  data() {
    return {
      ready: false,
      
      gallery: [],
      activeGallery: 0,
      galleryDirection: "right",
      animations: {
        right: {
          enter: "slideInLeft",
          leave: "slideOutRight",
        },
        left: {
          enter: "slideInRight",
          leave: "slideOutLeft",
        }
      }
    }
  },
  created() {
    this.initGallery()
  },
  mounted() {
    this.ready = true;
  },
  methods: {
    initGallery() {
      this.gallery = this.properties.images.map((image, i) => {
        image.index = i;
        let src = app.api.Utils.getMediaPath(image.src);
        image.src = src;
        app.api.Utils.preloadImage(src);
        return image
      });
    },
    prevGallery() {
      this.galleryDirection = "right";
      if (this.activeGallery - 1 >= 0) {
        this.activeGallery -= 1;
      }
    },
    nextGallery() {
      this.galleryDirection = "left";
      if (this.activeGallery + 1 < this.gallery.length) {
        this.activeGallery += 1;
      }
    },
    changeGallery(index) {
      this.galleryDirection = this.activeGallery > index ? 'right' : "left"
      this.activeGallery = index;
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    }
  },
  computed: {
    computedGallery() {
      return [this.gallery[this.activeGallery]]
    },
    isGallery() {
      return this.properties.images.length > 1
    },
    galleryNavButtons() {
      if (this.isGallery) return this.gallery;
    },
  }
};
</script>

<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';

.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 23px 25px;

  &>img {
    position: absolute;
    max-width: 1870px;
    max-height: 1034px;
    animation-fill-mode: both;
    animation-duration: 0.4s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

  &--singleimage {
    &>img {
      position: static;
    }
  }


  &__nav {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 40px;
    background: var(--dark-grey-opacity);
    height: 55px;
    border-radius: 1000px;
    padding: 0 10px;
    z-index: 2;

    &_arrow {
      width: 13px;
      height: 23px;
      background-image: url(@/assets/img/ui/arrow-right.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin: 0 15px;

      &_prev {
        transform: rotate(180deg);
      }
    }

    &_dot {
      position: relative;
      margin: 0 7px;
      width: 15px;
      height: 15px;
      border: solid 2px #fff;
      background-color: rgba(255, 255, 255, 0.007);
      border-radius: 100%;
      transition: ease 0.1s background-color;

      &:hover,
      &--active {
        background-color: #fff;
      }

      &::after {
        content: "";
        cursor: inherit;
        position: absolute;
        width: 220%;
        height: 220%;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  .closebutton {
    position: fixed;
  }

  @include tablet-down-portrait {
    &--singleimage { 
      display: block;
      overflow: auto;
      padding: 0;
    }

    &>img {
      display: block;
      width: auto;
      height: 100%;
    }
  }

  @include mobile-only-portrait {

  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0);
    opacity: 0;
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
    opacity: 0;
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

.galleryNavTransition-enter-active {
  transition-property: transform, opacity;
  transition-duration: 0.4s;
  transition-timing-function: var(--tk-easeinout);
}

.galleryNavTransition-leave-active {
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: var(--tk-easeinout);
}

.galleryNavTransition-enter-from {
  transform: translateY(40px);
  opacity: 0;
}

.galleryNavTransition-leave-to {
    transform: translateY(40px);
    opacity: 0;
}
</style>