<template>
    <article id="BurgerMenu">
        <div class="BurgerMenu__top">
            <h3>{{ burgerMenuContent.data.title }}</h3>
            <button class="close" @click="closeBurgerMenu()" aria-label="menu"><img src="@/assets/img/ui/close.svg" alt="menu" /></button>
        </div>
        <div class="BurgerMenu__links" v-if="burgerMenuContent.data.links.length > 0">
            <router-link  v-for="link in burgerMenuContent.data.links" :key="link.text" :to="getInternalLinkRoute(link.sceneID, link.contentID)" @click="trackNavigation(link.contentID)">{{link.text}}</router-link>
        </div>
    </article>
</template>
<script>
export default {
    methods: {
        closeBurgerMenu() {
            this.$emit("closeBurgerMenu")
        },
        getInternalLinkRoute(sceneID, contentID) {
            return app.api.PageManager.getRouteFromIDs(sceneID, contentID)
        },
        trackNavigation(contentID) {
            app.api.Tracking.event("BurgerMenu", "OpenedContent", app.api.PageManager.getContentComponentFromID(contentID).name)
        }
    },
    computed: {
        burgerMenuContent() {
            return app.api.PageManager.getCustomComponentFromType("burgerMenu");
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/sass/_responsiveness.scss';

#BurgerMenu {
    position: absolute;
    right: 0;
    top: 0;
    margin: 15px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);
    width: 800px;
    background: var(--main-color);
    text-align: left;
    color: #fff;
    z-index: 10;
    overflow-y: auto;
    box-shadow: var(--shadow-huge);

    .BurgerMenu__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px 0 40px;
        border-bottom: 1px solid #fff;
        
        h3 {
            font-weight: bold;
            // font-size: 46px;
            font-size: 2.2rem;
            margin: 40px 0 20px;
        }
    }

    .BurgerMenu__text {
        padding: 35px 20px 16px 40px;
        border-bottom: 1px solid #fff;
        font-size: 26px;
        line-height: 38px;
    }    

    .BurgerMenu__links { 
        padding: 10px 20px 0 20px;

        a {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 80px;
            // font-size: 28px;
            font-size: 1.55rem;
            margin-bottom: 4px;
            padding:0 20px;

            &::after {
                content: '';
                width: 21px;
                height: 33px;
                background-image: url(@/assets/img/ui/arrow-right.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                transition: transform .2s var(--tk-easeinout);
            }

            &:focus {
                background-color: rgba(255,255,255,0.1);
            }
        }
    }
    
    @include tablet-down-portrait {
        width: calc(100% - 30px);
        
        .BurgerMenu__top {
            padding: 0 34px 0 40px;
            
            h3 {
                // font-size: 36px;
                font-size: 2rem;
                margin: 30px 0 20px;
            }
            
            .close {
                img {
                    width: 30px;
                }
            }
        }

        .BurgerMenu__text {
            padding: 24px 44px 10px 40px;
            // font-size: 20px;
            font-size: 1.11rem;
            line-height: 36px;
        }    

        .BurgerMenu__links { 
            padding: 10px 24px 0 20px;

            a {   
                font-size: 24px;

            }
        }
    }
    
    @include mobile-only-portrait {
        .BurgerMenu__top {
            padding: 0 16px 0 20px;
            
            h3 {
                font-size: 26px;
                font-size: 1.44rem;
                margin: 24px 0 20px;
            }
            
            .close {
                img {
                    width: 24px;
                }
            }
        }

        .BurgerMenu__text {
            padding: 24px 24px 10px 20px;
            // font-size: 18px;
            font-size: 1rem;
            line-height: 27px;
        }    

        .BurgerMenu__links { 
            padding: 8px 24px 0 20px;

            a {
                // height: 60px;
                height: 3.3rem;
                // font-size: 20px;
                font-size: 1.11rem;
                padding:0;

                &::after {
                    width: 14px;
                    height: 25px;
                }
            }
        }
    }
}


.toggleBurgerMenu-enter-active {
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-timing-function: var(--tk-easeinout);
}

.toggleBurgerMenu-leave-active {
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: var(--tk-easeinout);
}

.toggleBurgerMenu-enter-from {
  transform: translateX(120px);
  opacity: 0;
  
  @include mobile-only-portrait {
    transform: translateX(60px);
  }
}

.toggleBurgerMenu-leave-to {
    opacity: 0;
}
</style>