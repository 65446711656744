import makeDataStore from '../LocalDataStore';
import PageManager from './PageManager';
import Utils from './Utils';
import Tracking from './Tracking';
import AudioManager from './AudioManager';


export default function ApiManager(app) {
    return Object.freeze({
        initializePreApis,
        initializePostApis
    })


    // Initialize Api functions that has to be run before running the core features such as fetching manifest etc. (Some of these require functions inside the API)
    async function initializePreApis() {
        // init data storage in localstorage of the browser and access through app.api.
        makeDataStore(app);
        
        // Helper methods managing pages and router
        PageManager(app);
        
        // Various other helper methods
        Utils(app);
        
        
        return Promise.resolve()
    }
    

    // Initialize Api functions that does not need to be run before running the core features such as fetching manifest etc. (If api is not crucial for running the core features)
    async function initializePostApis() {
        // Tracking manager
        Tracking(app);

        // Sound manager
        AudioManager(app)


        return Promise.resolve()
    }
}
