<template>
    <div class="articleCards">
        <articleCard v-for="card in content.cards" :key="card.title" :card="card"></articleCard>
    </div>
</template>
<script>
import articleCard from './articleCard.vue';

export default {
    components: {
        articleCard
    },
    props: ["content"],
    methods: {
    },
    computed: {
    }
}
</script>
<style lang="scss">

.articleCards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 26px;
}

</style>