<template>
  <transition name="toggleContentComponent">
    <article class="fullscreenIframePlayer fullscreenVideo" v-if="ready">
      <iframe ref="youtube"
        if="properties.youtubeUrl !== ''"
        :src="youtubeUrl"
        frameborder="0"
        allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen" 
        msallowfullscreen="msallowfullscreen" 
        oallowfullscreen="oallowfullscreen" 
        webkitallowfullscreen="webkitallowfullscreen"></iframe>
        <button class="fullscreenVideo__close" @click="closeComponent"></button>
    </article>
  </transition>
</template>

<script>
export default {
  props: [
    "properties",
    "pageData",
    "slug"
  ],
  data() {
    return {
      ready: false,
      youtubeID: ''
    }
  },
  created() {
    if(this.properties.youtubeUrl !== '') 
      this.youtubeID = this.getYoutubeID(this.properties.youtubeUrl);
  },
  mounted() {
    this.ready = true
  },
  methods: {
    getYoutubeID(url) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    }
  },
  computed: {
    youtubeUrl () {
      return `https://www.youtube.com/embed/${this.youtubeID}`
    }
  }
};
</script>