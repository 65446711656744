<template>
    <article id="Assistent">
        <div class="Assistent__background" :class="{ 'Assistent__background--active': open }" @click="closeAssitent()"></div>
        <button class="Assistent__button" @click="openAssistent()" v-if="!open">
            <div class="circle">
                <img src="@/assets/img/ui/assistent_small.gif" alt="assistent">
            </div>
            <div class="label">Assistent</div>
        </button>

        <transition name="toggleAssistent">
            <div class="Assistent__content" v-if="open">
                <div class="Assistent__textarea">
                    <h6 v-html="lastCrumbTitle"></h6>
                    <div class="Assistent__textarea_text" v-html="assistentText"></div>
                    <img src="@/assets/img/ui/assistent_blue.gif" alt="">
                </div>
                <div class="Assistent__menu">

                    <div class="Assistent__menu__top">
                        <h3>{{ this.title }}</h3>
                        <button class="close" @click="closeAssitent()"><img src="@/assets/img/ui/close_blue.svg" alt="luk assistent" /></button>
                    </div>

                    <div class="Assistent__menu__scroll">
                        <!-- level 1 -->
                        <div v-for="(lvl1Item, i) in assistentTree.children"
                            :class="[getButtonCategory(lvl1Item), { active: activeCategories[1] == lvl1Item }]" :key="lvl1Item.name + i">

                            <div class="button level1" @click="setActive(lvl1Item, 1)" >
                                <span>{{ lvl1Item.name }}</span>
                            </div>

                            <div class="children" v-if="lvl1Item.children">

                                <!-- level 2 -->
                                <div v-for="lvl2Item in lvl1Item.children"
                                    :class="[getButtonCategory(lvl2Item), {active: activeCategories[2] == lvl2Item }]" :key="lvl2Item.name">

                                    <div class="button" @click="setActive(lvl2Item, 2)">
                                        <span>{{ lvl2Item.name }}</span>
                                    </div>

                                    <div class="children">

                                        <!-- level 3 -->
                                        <div v-for="lvl3Item in lvl2Item.children"
                                            :class="[getButtonCategory(lvl3Item), {active: activeCategories[3] == lvl3Item }]" :key="lvl3Item.name">

                                            <div class="button" @click="setActive(lvl3Item, 3)">
                                                <span>{{ lvl3Item.name }}</span>
                                            </div>

                                            <div class="children">
                                                
                                                <!-- level 4 -->
                                                <div v-for="lvl4Item in lvl3Item.children"
                                                    :class="[getButtonCategory(lvl4Item)]" :key="lvl4Item.name">

                                                    <div class="button">
                                                        <span>{{ lvl4Item.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </article>
</template>
<script>
export default {
    data() {
        return {
            open: false,
            assistentTree: {},
            title: "",
            activeCategories: []

        }
    },
    mounted() {
        this.assistentTree = this.getAssitentTree();
        this.title = this.assistentTree.data.title;
    },
    methods: {
        openAssistent() {
            this.open = true;
            app.api.Tracking.event("Assistant", "Toggled", "Open")
        },
        closeAssitent() {
            this.open = false;
            app.api.Tracking.event("Assistant", "Toggled", "Close")
        },
        getAssitentTree() {
            return app.api.PageManager.getCustomComponentFromType("assistent");
        },
        setActive(item, level) {

            if (item.type == "assistentCategory") {

                if (this.activeCategories[level] == item) {
                    // closing currently active
                    this.activeCategories[level] = null;
                } else {
                    // opening another active
                    this.activeCategories[level] = item;
                }

                // close descendants from chosen level
                for (var i = level + 1; i < this.activeCategories.length; i++) {
                    this.activeCategories[i] = null;
                }

            } else {
                app.api.PageManager.openExplicitContentComponentFromID(item.data.contentID, true)
                app.api.Tracking.event("Assistant", "OpenedContent", app.api.PageManager.getContentComponentFromID(item.data.contentID).name)
                //console.log("open content: " + item.data.contentID)
            }
        },
        getButtonCategory(button) {
            switch(button.type) {
                case "assistentCategory":
                    return "Assistent__category"

                case "assistentExternalLink":
                    return "Assistent__link_external"

                case "assistentInternalLink":
                    return "Assistent__link_internal"
            }
        }
    },
    computed: {
        lastCrumbTitle() {
            this.$route;
            var title = ""
            var breadcrumbs = app.api.PageManager.getBreadcrumbs().filter(crumb => crumb !== undefined && crumb.type !== "content")
            title = (breadcrumbs.length > 1) ? breadcrumbs[breadcrumbs.length - 1] : ""
            
            return title == "" ? app.manifest.data.metadata.title : title.title;
        },
        assistentText() {
            this.$route;

            var scenes = app.manifest.data.pages.filter(scene => scene.type === 'scene');
            //console.log(scenes)
            let scene = scenes.find(scene => scene.route == app.api.PageManager.getCurrentRoute().split(":")[0])
            //console.log(scene)

            if(!scene)
                return

            // Scene specific audio
            if (scene.components.find(component => component.type === 'assistentText')) {
                return scene.components.find(component => component.type === 'assistentText').properties.text
            }
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';

#Assistent {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 3;
    pointer-events: none !important;

    .Assistent__background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--main-color-transparent);
        opacity: 0;
        backdrop-filter: blur(10px);
        pointer-events: none;
        transition: opacity 0.4s;

        &--active {
            opacity: 1;
            pointer-events: all;
        }
    }

    .Assistent__button {
        position: absolute;
        right: 30px;
        bottom: 30px;
        width: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        transition: ease opacity 0.2s;

        .circle {

            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: #cccccccc;
            border: 1px solid #fff;
            border-radius: 50%;
            pointer-events: all;
            transition: transform 0.3s;
            display: flex;
            justify-content: center;
            overflow: hidden;

            &>img {
                position: absolute;
                width: 100px;
                height: auto;
            }
        }

        &>.label {
            position: absolute;
            color: var(--secondary-color);
            bottom: -7px;
            background-color: var(--main-color);
            border-radius: 20px;
            font-size: 16px;
            padding: 3px 10px;
        }
    }
}

.Assistent__content {
    position: absolute;
    width: 1200px;
    height: 1000px;
    box-shadow: 2px 2px 2px -5px var(--main-color);
    text-align: left;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-areas: "icon menu";
}

.Assistent__menu {
    grid-area: menu;
    background-color: #fff;
    display: grid;
    grid-template-rows: 100px auto;
    grid-template-areas: "top" "scroll";
    overflow: hidden;

    &__top {
        grid-area: top;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px 0 40px;
        border-bottom: 1px solid var(--main-color);

        h3 {
            font-weight: bold;
            // font-size: 46px;
            font-size: 2.55rem;
            margin: 40px 0 20px;
            color: var(--main-color);
        }
    }

    &__scroll {
        grid-area: scroll;
        height: 100%;
        overflow-y: scroll;
        padding: 20px;

        &>.Assistent__category {
            &>.children {
                padding-right: 20px;
                
                &>div {
                    &:first-of-type {
                        border-top: 1px solid var(--main-color);
                    }
                    border-bottom: 1px solid var(--main-color);
                }
            }
        }

        .button {
            position: relative;
            display: flex;
            height: 80px;
            align-items: center;
            justify-content: space-between;
            // font-size: 34px;
            font-size: 1.88rem;
            color: var(--main-color);
            cursor: pointer;
            user-select: none;

            &.level1 {
                margin: 0;
                padding: 0 24px;
                border-radius: 1000px;
                margin-bottom: 15px;
            }

            span {
                margin-right: 10px;
            }

            img {
                transition: transform .1s;
            }

            &:hover {   
                background-color: #eee;
            }

            &::after {
                content: '';
                width: 21px;
                height: 33px;
                background-image: url(@/assets/img/ui/arrow-right-blue.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                transition: transform .2s var(--tk-easeinout);
            }
        }

        .Assistent__category {
            &>.children {
                display: none;
                flex-direction: column;
                padding-left: 27px;
                height: 0px;
                overflow: hidden;
                transition: height 1s;
                margin-bottom: 15px;
            }

            &>.button {
                font-weight: bold;
            }


            &.active {
                &>.button {
                    &::after {
                        transform: rotate(90deg);

                    }
                }

                &>.button.level1 {
                    background-color: var(--main-color);
                    color: #fff;

                    &::after {
                        background-image: url(@/assets/img/ui/arrow-right.png);
                    }
                }

                &>.children {
                    display: flex;
                    height: fit-content;
                }
            }
        }

        .Assistent__link_external {
            &>.button {
                &::after {
                    background-image: url(@/assets/img/ui/external-link.png);
                    width: 26px;
                    height: 26px;
                }
            }
        }
    }
}

.Assistent__textarea {
    position: relative;
    grid-area: icon;
    overflow: hidden;
    padding: 37px 40px 0 40px;
    background-color: var(--main-color);
    color: #fff;

    h6 {
        font-size: 40px;
        margin-bottom: 18px;
    }

    &_text {
        z-index: 2;
        font-size: 30px;
        line-height: 37px;
    }
    
    &>img {
        position: absolute;
        right: -50px;
        bottom: -70px;
    }
}

@include tablet-down-portrait {
    .Assistent__content {
        height: 100%;   
        width: 100%;
        padding: var(--mobile-box-space);

        grid-template-columns: 100%;
        grid-template-rows: 2fr 1fr;
        grid-template-areas: "menu" "icon";
    }

    .Assistent__menu {
        &__top {
            padding: 0 44px 0 40px;

            h3 {
                // font-size: 36px;
                font-size: 2rem;
                margin: 34px 0 20px;
            }
            
            .close {
                img {
                    width: 30px;
                }
            }
        }

        &__scroll {
            .button {
                height: 74px;
                // font-size: 30px;
                font-size: 1.66rem;
            }
        }
    }

    .Assistent__textarea {
        padding: 37px 240px 0 40px;

        h6 {
            // font-size: 34px;
            font-size: 1.9rem;
            margin-bottom: 12px;
        }

        &_text {
            font-size: 26px;
            font-size: 1.44rem;
            line-height: 34px;
        }
        
        &>img {
            bottom: -180px;
        }
    }
}

@include mobile-only-portrait {
    .Assistent__content {
        grid-template-areas: "menu";
        grid-template-rows: initial;
    }


    .Assistent__menu {
        &__top {
            padding: 0 22px 0 32px;

            h3 {
                font-size: 26px;
                // font-size: 1.44rem;
                margin: 34px 0 20px;
            }

            .close {
                img {
                    width: 24px;
                }
            }
        }

        &__scroll {
            padding: 16px 10px;

            .button {
                // height: 60px;
                height: 3.3rem;
                // font-size: 18px;
                font-size: 1rem;

                &.level1 {
                    padding: 0 16px 0 20px;
                    margin-bottom: 12px;
                }

                &::after {
                    width: 14px;
                    height: 25px;
                }
            }

            .Assistent__category {
                &>.children {
                    padding-left: 22px;
                }
                
            }
        }
    }

     .Assistent__textarea {
         display: none;
        padding: 26px 120px 0 30px;

        h6 {
            font-size: 24px;
            margin-bottom: 12px;
        }

        &_text {
            font-size: 16px;
            line-height: 26px;
        }
        
        &>img {
            right: -46px;
            bottom: -67px;
            width: 200px;

        }
    }
}



.toggleAssistent-enter-active {
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: var(--tk-easeinout);
}

.toggleAssistent-leave-active {
  transition-property: transform, opacity;
  transition-duration: 0.1s;
  transition-timing-function: var(--tk-easeinout);
}

.toggleAssistent-enter-from {
    transform: translateX(200px);
  opacity: 0;
}

.toggleAssistent-leave-to {
    opacity: 0;
}
</style>