<template>
    <div class="videoEmbed">
        <iframe ref="youtube"
            :src="embedSrc"
            frameborder="0"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen" 
            msallowfullscreen="msallowfullscreen" 
            oallowfullscreen="oallowfullscreen" 
            webkitallowfullscreen="webkitallowfullscreen"></iframe>
    </div>
</template>
<script>
export default {
    props: ["content"],
    data() {
        return {
            embedSrc: '',
            youtubeID: ''
        }
    },
    created() {
        if(this.content.source !== '') 
            this.youtubeID = this.getYoutubeID(this.content.source);

        if(this.youtubeID != null) {
            this.embedSrc = this.youtubeURL
        } else {
            this.embedSrc = this.content.source
        }
    },  
    methods: {
        getYoutubeID(url) {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            var match = url.match(regExp);
            return (match && match[2].length === 11) ? match[2] : null;
        }
    },
    computed: {
        youtubeURL () {
            return `https://www.youtube.com/embed/${this.youtubeID}`
        }
    }
}
</script>
<style lang="scss" scoped>
iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
}
</style>