<template>
    <div class="articleImage" :class="{'articleImage--hasLink': content.link}"  @click="openContent">
        <img :src="computedSrc" :alt="content.image.name">
        <button v-if="content.link" aria-label="fuldskærmsvisning">
            <img src="@/assets/img/ui/fullscreen.svg" alt="fuldskærmsvisning">
        </button>
    </div>
</template>
<script>
export default {
    props: ["content"],
    methods: {
        openContent() {
            if(this.content.link)
                app.api.PageManager.openExplicitContentComponentFromID(this.content.link)
        }
    },
    computed: {
        computedSrc() {
            if(this.content.image)
                return app.api.Utils.getMediaPath(this.content.image.src)
        }
    }
}
</script>
<style lang="scss">
$button-size: 36px;

.articleImage {
    position: relative;
    width: 100%;
    margin-bottom: 50px;

    &>img {
        width: 100%;
        display: block;
    }

    button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 12px;
        bottom: 16px;
        width: $button-size;
        height: $button-size;
        border-radius: 100%;
        box-shadow: var(--shadow-medium);
        z-index: 2;
        transition: transform var(--tk-easeinout) 0.15s;
        background-size: 100%;
        background-color: #fff;

        &> img {
            width: 20px;
        }
    }

    &--hasLink {
        cursor: pointer;
    }    

    &:hover {
        button {
            transform: scale(1.1);
        }
    }
}

</style>