import config from "@/config"

export default function makeDataStore(app) {
    if ('store' in app.api) {
        throw Error('store already defined in app.api')
    }

    let data = initializeStorage()

    app.api.store = Object.freeze({
        get,
        set,
        remove,
        has,
        list,
    })

    function initializeStorage() {
        // If the key for the app isn't in localStorage, create an object with a language key and set it to the app's language
        if (!localStorage.getItem(config.name)) {
            let initialData = { lang: config.language }
            localStorage.setItem(config.name, JSON.stringify(initialData))
            return initialData
        }
        // Otherwise, get the data from localStorage
        return JSON.parse(localStorage.getItem(config.name));
    }

    function set(key, keyVal) {
        // Set the value of the given key in the data object to the given value, save the updated data to localStorage, and return the new value
        data[key] = keyVal
        save()
        return data[key]
    }

    function get(key) {
        // If the key exists in localStorage, return its value. Otherwise, log an error and return false.
        if (has(key))
            return JSON.parse(localStorage.getItem(config.name))[key];
        console.log(`The specific key: "${key}" was not found in the local storage`)    
        return false;
    }

    function has(key) {
        // Return true if the given key exists in localStorage
        var o = JSON.parse(localStorage.getItem(config.name)) || {};
        return (key in o);
    }

    function remove(key, prop) {
        // If the key exists in the data object, remove it (or a property of it), save the updated data to localStorage, and return true.
        if (has(key)) {
            if (prop && prop in data[key]) {
                delete data[key][prop]
                save({ key: `${key}.${prop}`, action: 'remove' })
            } else {
                delete data[key]
                save({ key, action: 'remove' })
            }
            return true;
        }
        
        return false
    }

    function list() {
        // Return an array of all the keys in the data object.
        return Object.keys(data);
    }

    function save() {
        // Save the current state of the data object in the localStorage.
        localStorage.setItem(config.name, JSON.stringify(data));
    }
}