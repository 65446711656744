<template>
    <transition name="HotspotEntrance">
        <router-link class="Hotspot" v-show="ready" :to="hotspot.route" :class="[getHotspotMutatorClasses, getHotspotContentClass, getHotspotTypeClass]" :style="[getPosition]" @click="hotspotPressed">
            <div class="Hotspot__button" :style="[getContinuousHotspotAnimation, getAlternateHotspotIcon]"></div>
            <span v-html="getHotspotTitle"></span>
        </router-link>
    </transition>
</template>

<script>

export default {
    props: ['hotspot'],
    data() {
        return {
            ready: false
        }
    },
    mounted() {
        this.renderHotspotCascade();
    },
    methods: {
        renderHotspotCascade() {
            let delay = 0;
            delay = (this.hotspot.index * 130) + 300;

            setTimeout(() => {
                this.ready = true;
            }, delay);
        },
        getRandomAnimationDelay(min, max) {
            //return a random value for randomly timed animations
            return Math.random() * (max - min) + min;
        },
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        }
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        },
        getHotspotTypeClass() {
            let hotspotPrefix = "Hotspot--"            

            switch(this.hotspot.data.hotspotType) {
                case "No Label":
                    return `${hotspotPrefix}NoLabel` 

                // more types can be added here through the backend hotspotType data value.

                default: 
                    return
            }
        },
        getHotspotMutatorClasses() {
            return {
                'Hotspot--flipped': this.hotspot.data.flipped,
                'Hotspot--hidden': this.hotspot.data.hidden,
                'Hotspot--customicon': this.hotspot.data.alternateHotspotIcon !== null
            }
        },
        getHotspotContentClass() {
            if(this.hotspot.componentData) {
                return `Hotspot--${this.hotspot.componentData.type}`
            } else {
                return
            }
        },
        getContinuousHotspotAnimation() {
            // assign the looping animation on hotspots, as well as keeping them synced with resetHotspot.
            return {
                'animation-name': "hotspotLoopPulse",
                'animation-iteration-count': 'infinite',
                'animation-duration': '15s',
                'animation-delay': this.getRandomAnimationDelay(2, 14).toFixed(1) + 's'
            }
        },
        getAlternateHotspotIcon() {
            if(this.hotspot.data.alternateHotspotIcon !== null) {
                return {
                    'background-image': `url(${app.api.Utils.getMediaPath(this.hotspot.data.alternateHotspotIcon.src)})`
                };
            }
        }
    }
}
</script>


<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';
$hotspot-height: 50px;
$hotspot-border-radius: 30px;

.Hotspot {
    display: flex;
    border-radius: $hotspot-border-radius;
    background: var(--main-color);
    // overflow: hidden;

    .Hotspot__button {
  
        display: flex;
        justify-content: center;
        align-items: center;
        height: $hotspot-height;
        width: $hotspot-height;
        background-color: var(--main-color);
        background-image: url(@/assets/img/ui/arrow.svg);
        background-size: 25px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: $hotspot-border-radius;
        flex-shrink: 0;
    }
    
    span {
        display: flex;
        align-items: center;
        white-space: pre-wrap;
        font-weight: bold;
        text-align: left;
        color: var(--secondary-color);
        // font-size: 20px;
        font-size: 1.2rem;
        min-height: $hotspot-height;
        padding: 5px 22px 5px 10px;
    }
    
    
    &--flipped {
        flex-direction: row-reverse;
        
        span {
            padding: 5px 10px 5px 22px;
            margin-left: auto;
            border-radius: 24px;
        }
    }
    
    // Styles for specifc components that hotspot can open
    &--fullscreenVideo, &--fullscreenIframePlayer {
        .Hotspot__button {
            background-image: url(@/assets/img/ui/play.svg);
        }
    }
    &--externalLink {
        .Hotspot__button {
            background-image: url(@/assets/img/ui/globe.svg);
        }
    }
    &--mail {
        .Hotspot__button {
            background-image: url(@/assets/img/ui/mail.svg);
        }
    }

    // Styles for specific hotspot types defined by getHotspotTypeClass()
    &--NoLabel {
        span {
            display: none;
        }
    }

    @include tablet-down-portrait {

        flex-direction: row-reverse !important;
        width:calc(100% - 250px + 5rem);
        max-width: 350px;
       
        
        span {
            padding: 6px 10px 6px 22px;
            margin-left: auto !important;
            min-width: calc(100% - $hotspot-height);
            // font-size: 16px;
            font-size: 0.9rem;
        }
    }
}

/* entrance animation */
.HotspotEntrance-enter-active {
    transition: all 1s var(--tk-easeinout);
}
.HotspotEntrance-leave-active {
    transition: all 0.3s var(--tk-easeinout);
}
.HotspotEntrance-enter-from {
    opacity: 0;
    transform: translateY(-200%);
}
.HotspotEntrance-leave-to {
    opacity: 0;
}

/* default*/
@keyframes hotspotLoopPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
        transform: scale(0.3);
    }

    7% {
        box-shadow: 0 0 0 220px rgba(255, 255, 255, 0);
        transform: scale(1.05);
    }

    10% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        transform: scale(1);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        transform: scale(1);
    }
}
</style>