<script>
export default {
  render: () => null,
  props: [
    "properties",
    "pageData",
    "slug"
  ],
  mounted() {

    var route = app.api.PageManager.getRouteFromID(this.properties.sceneID);
    app.api.PageManager.navigateToRoute(route);
    
  },
};
</script>