<template>
    <article class="articleCard">
        <div class="articleCard__img">
            <img :src="computedSrc" :alt="card.image.name">
        </div>
        <div class="articleCard__text">
            <h4 v-html="card.title"></h4>
            <div v-html="card.text"></div>
        </div>
        <div class="articleCard__links">
            <a v-for="link in card.links" :href="link.link" :class="getLinkType(link.type)" :key="link.link" target="_blank" @click="trackNavigation(link.link)">
                {{getLinkText(link)}}
            </a>
        </div>
    </article>
</template>
<script>
export default {
    props: ["card"],
    methods: {
        getLinkType(type) {
            return `articleCard__links_link--${type}`
        },
        getLinkText(link) {
            switch(link.type) {
                case "other":
                    return link.title
                case "appstore":
                    return "App Store"
                case "playstore": 
                    return "Play Store"
            }
        },
        trackNavigation(link) {
            app.api.Tracking.event("Article", "ExternalLink", link)
        }
    },
    computed: {
        computedSrc() {
            if(this.card.image)
                return app.api.Utils.getMediaPath(this.card.image.src)
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';
$card-margin: 20px;

.articleCard {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    width: calc(50% - ($card-margin / 2));
    min-width: calc(50% - ($card-margin / 2));
    margin-bottom: $card-margin;
    border-radius: 5px;
    border: solid 1px var(--grey);

    &__img {
        position: relative;
        height: 200px;
        width: 100%;
        overflow: hidden;
        border-radius: 5px 5px 0 0;
        background: var(--light-grey);

        img {
            position: absolute;
            max-width: 100%;
            object-fit: cover;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    
    &__text {
        padding: 20px 24px 0 24px;

        h4 {
            font-weight: bold;
            // font-size: 24px;
            font-size: 1.33rem;
            margin-bottom: 6px;
        }

        & > div {
            // font-size: 18px;
            font-size: 1rem;
            // line-height: 26px
            font-size: 1.44rem;
        }
    }

    &__links {
        display: flex;
        align-items: flex-end;
        flex-grow: 1;
        padding: 0 24px 15px 24px;
        // font-size: 17px;
        font-size: 0.95rem;

        a {
            position: relative;
            margin-right: 16px;

            &::before {
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 7px;
                margin-bottom: -3px;
            }

            &:last-of-type { 
                margin-right: 0;
            }

            &.articleCard__links_link--other {
                &::before {
                    background-image: url(@/assets/img/ui/external-link.png);
                }
            }

            &.articleCard__links_link--playstore {
                &::before {
                    background-image: url(@/assets/img/ui/play-icon.png);
                }
            }

            &.articleCard__links_link--appstore {
                &::before {
                    background-image: url(@/assets/img/ui/apple-icon.png);
                }
            }
        }
    }
    
    @include tablet-down-portrait {
        &__text {
            h4 {
                // font-size: 20px;
                // line-height: 26px;
                font-size: 1.1rem;
                line-height: 1.44rem;
            }

            & > div {
                // font-size: 16px;
                // line-height: 24px;
                font-size: 0.9rem;
                line-height: 1.33rem;
            }
        }
    }

    
    @include mobile-only-portrait {
        min-width: 100%;
        min-height: none;
    }
}

</style>