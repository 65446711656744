<template>
    <div id="BackButton">
        <div>
            <button :class="{ 'Backbutton--inactive': onHomePage }" @click="navigateBack" aria-label="tilbage">
                <img src="@/assets/img/ui/arrow-return.svg">
                <span>Tilbage</span>
            </button>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        navigateBack() {
            app.api.PageManager.navigateToParentScene();
        }
    },
    computed: {
        onHomePage() {
            this.$route;
            return app.api.PageManager.onHomePage()
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/sass/_responsiveness.scss';

#BackButton {
    width: fit-content;
    position: absolute;
    left: 50px;
    bottom: 50px;
    display: flex;
    justify-content: center;


    @include tablet-down-portrait {
        // width: calc(100% - 50px);
        // max-width: 360px;
        width:100%;
        bottom: auto;
        top: calc(28% - 75px);
        left: 0px;
        justify-self: center;

        &>div {
            width: calc(100% - 160px);
            max-width: 350px;
        }
    }

    @include mobile-only {
        top: calc(24% - 75px);
        // font-size: 16px;
        font-size: 0.9rem;
    }

    
    @include x-small-mobile-only {
        top: calc(27% - 75px);
    }

    button {

        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #00627D33;
        border: solid;
        border-radius: 25px;
        border: 1px solid #fff;
        color: #fff;
        height: 50px;
        padding: 0 15px;
        backdrop-filter: blur(4px);

        img {
            width: 20px;
            margin-right: 10px;
        }
    }
}

.Backbutton--inactive {
    opacity: 0;
    pointer-events: none !important;
}
</style>