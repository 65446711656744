<template>
    <h2 class="subtitle" v-html="content.text"></h2>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';

.subtitle {
    //font-size: 28px;
    font-size: 1.55rem;

    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: bold;

    @include tablet-down-portrait {
        // font-size: 24px;
        font-size: 1.33rem;
        margin-top: 36px;
        margin-bottom: 26px;
    }

    @include mobile-only-portrait {
        // font-size: 20px;
        font-size: 1.11rem;
        margin-top: 30px;
        margin-bottom: 20px;
    }
}
</style>