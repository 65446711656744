<template>
    <router-link :to="homeURL" id="logo">
        <img class="logo__mobile" src="@/assets/img/ui/logo.svg" alt="logo">
        <img class="logo__desktop" src="@/assets/img/ui/logo_desktop.svg" alt="logo">
    </router-link>
</template>
<script>
export default {
    computed: {
        homeURL() {
            return app.api.PageManager.getRootPage().route
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/sass/_responsiveness.scss';

#logo {
    width: 380px;
    z-index: 3;

    cursor: pointer;

    &>img {
        width: 100%;

        &.logo__mobile {
            display: none;
        }
    }


    @include tablet-down-portrait {
        width: 45px;

        &>img {

            &.logo__mobile {
                display: initial;
            }

            &.logo__desktop {
                display: none;
            }
        }

        @include mobile-only {
            width: 35px;
        }

    }
}
</style>