<template>
    <h1 class="title" v-html="content.text"></h1>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';

.title {
    // font-size: 50px;
    // line-height: 52px;

    font-size: 2.77rem;
    line-height: 2.8rem;

    font-weight: bold;
    margin-bottom: 70px;

    @include tablet-down-portrait {
        // font-size: 40px;
        // line-height: 42px;

        font-size: 2.22rem;
        line-height: 2.33rem;

        margin-bottom: 45px;
    }

    @include mobile-only-portrait {
        // font-size: 28px;
        // line-height: 32px;

        font-size: 1.55rem;
        line-height: 1.77rem;

        margin-bottom: 25px;
    }
}
</style>