<template>
    <div id="Breadcrumbs">
        <h3> <router-link :to="homeURL" id="logo">{{title}}</router-link></h3>
        <nav>
            <router-link v-if="lastCrumb" :to="lastCrumb.route" :key="lastCrumb.route"
                v-html="lastCrumb.title"></router-link>
        </nav>
    </div>
</template>
<script>
export default {
    computed: {
        title() {
            return app.manifest.data.metadata.title
        },
        lastCrumb() {
            this.$route;
            var breadcrumbs = app.api.PageManager.getBreadcrumbs().filter(crumb => crumb !== undefined && crumb.type !== "content")
            return (breadcrumbs.length > 1) ? breadcrumbs[breadcrumbs.length - 1] : null;
        },
        homeURL() {
            return app.api.PageManager.getRootPage().route
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';

#Breadcrumbs {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    color: #fff;
    pointer-events: none;
    font-size: 20px;
    z-index: 2;
    text-align: left;
    pointer-events: none;

    &>h3 {
        color: #fff;
        font-size: 40px;
        text-wrap: nowrap;
    }

    &>nav {
        display: flex;
        align-items: center;

        a {
            position: relative;
            padding: 0 10px;
            pointer-events: all;
            white-space: nowrap;

            &::after {
                content: '/';
                position: absolute;
                pointer-events: none;
                margin-left: 4px;
                color: #fff;
            }

            &:last-of-type {
                cursor: default;
                // color: var(--main-color);

                &::after {
                    content: none;
                }
            }

            &:first-of-type {
                border-left: 2px solid #fff;
                margin-left: 20px;
                padding-left: 20px;
            }
        }
    }

    @include tablet-down-portrait {
        flex-direction: column;
        align-items: center;
        text-align: center;

        h3 {
            font-size: 33px;
        }

        &>nav {

            a {
                &:first-of-type {
                    border-left: none;
                    margin-left: 10px;
                    padding-left: 10px;
                }
            }
        }
    }

    @include mobile-only {
        h3 {
            // font-size: 20px;
            font-size:1.1rem;
        }
    }

    @include x-small-mobile-only {
        h3 {
            font-size: 17px;

        }
    }
}
</style>