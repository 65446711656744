<template>
    <div class="bodyText" v-html="content.text" :class="{'bodyText--highlighted': content.highlight}"></div>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">

.bodyText {
    margin-bottom: 30px;

    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: underline;
    }

    &--highlighted { 
        position: relative;
        color: #fff;
        margin-top: 70px;
        margin-bottom: 80px;

        *{
            position: relative;
            z-index: 2;
        }

        &::after {
            content: '';
            position: absolute;
            top: -30px;
            left: -30px;
            width: calc(100% + 60px);
            height: calc(100% + 60px);
            background: var(--main-color);
            z-index: 1;
        }
    }
}
</style>