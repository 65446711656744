<template>
  <nav id="UiLayer" :style="[getMaximizedSizeAndOffset]">
    <div class="UiLayer__topbar" v-if="!hasFullscreenContentComponent">
      <Logo></Logo>
      <Breadcrumbs></Breadcrumbs>
      <BurgerButton @click="toggleBurgerMenu"></BurgerButton>
    </div>
    <BackButton v-show="!hasFullscreenContentComponent"></BackButton>
    <Assistent v-show="!hasFullscreenContentComponent"></Assistent>
    <transition name="toggleBurgerMenu">
      <BurgerMenu v-if="!hasFullscreenContentComponent && burgerMenuOpen" @closeBurgerMenu="closeBurgerMenu"></BurgerMenu>
    </transition>
  </nav>
</template>
<script>
import Logo from "../ui/Logo.vue";
import Breadcrumbs from "../ui/Breadcrumbs.vue";
import BackButton from "../ui/BackButton.vue";
import Assistent from "../ui/Assistent.vue";
import BurgerMenu from "../ui/BurgerMenu.vue";
import BurgerButton from "../ui/BurgerButton.vue";

export default {
  components: {
    // Ui Components
    Logo,
    Breadcrumbs,
    BackButton,
    Assistent,
    BurgerButton,
    BurgerMenu
  },
  data() {
    return {
      burgerMenuOpen: false
    };
  },
  methods: {
    toggleBurgerMenu() {
      this.burgerMenuOpen = !this.burgerMenuOpen
      app.api.Tracking.event("BurgerMenu", "Toggled", this.burgerMenuOpen === true ? "Open" : "Close")
    },
    closeBurgerMenu() {
      this.burgerMenuOpen = false
      app.api.Tracking.event("BurgerMenu", "Toggled", "Close")
    }
  },
  computed: {
    onHomePage() {
      this.$route;
      return app.api.PageManager.onHomePage();
    },
    hasFullscreenContentComponent() {
      this.$route
      var hasFullscreenComponent = false;
      if (!app.api.PageManager.getIsContentComponentOpen())
        return hasFullscreenComponent

      var fullscreenComponents = ["article", "fullscreenVideo", "gallery", "accordionNavigation"];
      var openComponents = app.api.PageManager.getOpenContentComponents()
      var i = 0;

      while (i < openComponents.length) {
        if (fullscreenComponents.includes(openComponents[i].type)) {
          hasFullscreenComponent = true
        }
        i++
      }

      return hasFullscreenComponent
    },
    getMaximizedSizeAndOffset() {
      return this.$store.getters.getMaximizedSizeAndOffsetValue;
    },
  }
};
</script>

<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';

#UiLayer {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  * {
    pointer-events: all;
  }
}

.UiLayer__topbar {
  position: absolute;
  top: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;

  @include tablet-down-portrait {
    padding: 0 30px;
  }
}


</style>