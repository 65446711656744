<template>
  <transition name="toggleContentComponent">
    <article class="accordionNavigation" v-if="ready">
        <div class="Assistent__menu">
            <div class="Assistent__menu__top">
                <h3>{{ this.properties.title }}</h3>
                <button class="close" @click="closeComponent()"><img src="@/assets/img/ui/close_blue.svg" /></button>
            </div>

            <div class="Assistent__menu__scroll">
                <!-- level 1 -->
                <div v-for="(lvl1Item, i) in accordionTree.children"
                    :class="[getButtonCategory(lvl1Item),{ active: activeCategories[1] == lvl1Item }]" :key="lvl1Item.name + i">

                    <div class="button level1" @click="setActive(lvl1Item, 1)" >
                        <span>{{ lvl1Item.name }}</span>
                    </div>

                    <div class="children" v-if="lvl1Item.children">

                        <!-- level 2 -->
                        <div v-for="lvl2Item in lvl1Item.children"
                            :class="[getButtonCategory(lvl2Item), {active: activeCategories[2] == lvl2Item }]" :key="lvl2Item.name">

                            <div class="button" @click="setActive(lvl2Item, 2)">
                                <span>{{ lvl2Item.name }}</span>
                            </div>

                            <div class="children">

                                <!-- level 3 -->
                                <div v-for="lvl3Item in lvl2Item.children"
                                    :class="[getButtonCategory(lvl3Item), {active: activeCategories[3] == lvl3Item }]" :key="lvl3Item.name">

                                    <div class="button" @click="setActive(lvl3Item, 3)">
                                        <span>{{ lvl3Item.name }}</span>
                                    </div>

                                    <!-- level 4 -->
                                    <div class="children">
                                        <div v-for="lvl4Item in lvl3Item.children"
                                            :class="[getButtonCategory(lvl4Item)]" :key="lvl4Item.name">

                                            <div class="button">
                                                <span>{{ lvl4Item.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </article>
  </transition>
</template>

<script>


export default {
  props: [
    "properties",
    "pageData",
    "slug"
  ],
  data() {
    return {
        ready: false,

        accordionTree: {},
        activeCategories: []
    }
  },
  created() {
  },
  mounted() {
    this.ready = true;
    this.accordionTree = this.getAccordionTree();
  },
  methods: {
    getAccordionTree() {
        return app.api.PageManager.getContentComponentFromID(this.properties.componentID);
    },
    setActive(item, level) {
        if (item.type == "assistentCategory") {

            if (this.activeCategories[level] == item) {
                // closing currently active
                this.activeCategories[level] = null;
            } else {
                // opening another active
                this.activeCategories[level] = item;
            }

            // close descendants from chosen level
            for (var i = level + 1; i < this.activeCategories.length; i++) {
                this.activeCategories[i] = null;
            }

        } else {
            app.api.PageManager.openExplicitContentComponentFromID(item.data.contentID, false)
        }
    },
    getButtonCategory(button) {
        switch(button.type) {
            case "assistentCategory":
                return "Assistent__category"

            case "assistentExternalLink":
                return "Assistent__link_external"

            case "assistentInternalLink":
                return "Assistent__link_internal"
        }
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    }
  },
  computed: {
  }
};
</script>

<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';

.accordionNavigation {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    text-align: left;

    .Assistent__menu {
        height: 100%;
        width: 860px;
    }

    @include tablet-down-portrait {
        width: 100%;
    }
}
</style>