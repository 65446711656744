import {Howl, Howler} from 'howler';
import store from '../../store/store.js';

export default function AudioManager(app) {
    var scenes = app.manifest.data.pages.filter(scene => scene.type === 'scene');
    var audioInstances = {};
    var playlist = [];
    var volume = 0.2;
    
    var tempVolume = null;
    var currentlyPlayingAudioId = 0;
    var currentlyPlayingindex = null;
    var idCounter = 0;


    app.api.AudioManager = Object.freeze({
        // Get variable of all the active audio objects using Howler
        get audioInstances() {
            return audioInstances;
        },  
        // Get the current playlist
        get playlist() {
            return playlist;
        },
        // Get the volume value
        get volume() {
            return volume;
        },  

        // Check if audio should be played based on the current route
        checkIfAudio,

        // Next audio in the playlist
        next,
        
        // Previous audio in the playlist
        previous,

        // Mute audio
        mute,

        // Unmute audio
        unmute,

        // Set volume to a specifik level
        setVolume,

        // Play specific audio clips once
        playAudioFromSrc,
        playSpecificAudio
    })
 
    // initial check on first intializing the app
    checkIfAudio(app.api.PageManager.getCurrentRoute());

    // listener for other audio sources to mute AudioManager temporarily
    otherAudioListener();


    function startAudio(index) {
        // Clear audio from audioInstances not in the playlist
        clearAudioNotInPlaylist();
        
        // Check if audio to be played is already playing
        if(checkIfRelevantAudioPlaying())
            return

        // Assign a unique audio ID
        currentlyPlayingAudioId = idCounter;
        const audioId = 'audio_' + currentlyPlayingAudioId;
        currentlyPlayingindex = index;
        
        console.log(`Audio => \Playing: ${playlist[index].title}`);

        // Create and play a Howl instance (The actual audio object that will be played)
        const player = new Howl({
            src: playlist[currentlyPlayingindex].src,
            volume: volume,
            onend: function() {

                // Clear audio instance when finished
                clearAudioInstance(audioId);

                // Start playing the next audio in the playlist or loop back to the beginning
                next();
            }
        })
        
        // Store the Howl instance in audioInstances
        audioInstances[audioId] = player;
        
        // Increment the audio ID counter to make the stored audio instances unique.
        idCounter++;

        // Play the Howl audio
        player.play();   
    }


    // Check if relevant audio is already playing
    // (instead of changing audio, just keep playing if it is in the playlist 
    // to avoid starting audio over every navigation).
    function checkIfRelevantAudioPlaying() {
        let playing = false;

        for (const audioId in audioInstances) {
            if (audioInstances.hasOwnProperty(audioId)) {
                let src = audioInstances[audioId]._src;

                if(playlist.find(audio => audio.src === src))  {
                    playing = true;
                }
            }
        }

        return playing;
    }


    function addAudioToPlaylist(audio) {
        // Simplify data for playlist 
        var enrichedAudioObject = {
            title: audio.name ? audio.name : 'No audio title',
            src: app.api.Utils.getMediaPath(audio.src)
        }

        playlist.push(enrichedAudioObject)
    }

    // Next audio track in playlist
    function next() {
        console.log(`Audio => \Next`);
        clearAllAudioInstances();
        
        if(playlist[currentlyPlayingindex + 1]) {
            startAudio(currentlyPlayingindex + 1);
        } else if (playlist.length > 0) {
            startAudio(0)
        } else {
            console.log(`Audio => \No audio in playlist`);
        }
    }
    
    // Previous audio track in playlist
    function previous() {
        console.log(`Audio => \Previous`);
        clearAllAudioInstances();
        
        if(playlist[currentlyPlayingindex - 1]) {
            startAudio(currentlyPlayingindex - 1);
        } else if (playlist.length > 0) {
            startAudio(playlist.length - 1);
        } else {
            console.log(`Audio => \No audio in playlist`);
        }
    }

    function mute() {
        // Store the old volume value
        tempVolume = volume;
        volume = 0;

        // Set volume for all active audioInstances
        for (const audioId in audioInstances) {
            if (audioInstances.hasOwnProperty(audioId)) {
                const audio = audioInstances[audioId];
                
                // Set volume to 0 for all audio instances
                fadeVolumeOut(audio);
            }
        }

        console.log(`Audio => \Muted`);
    }
    
    function unmute() {
        // Use the stored volume value.
        volume = tempVolume;
        
        // Set volume for all active audioInstances
        for (const audioId in audioInstances) {
            if (audioInstances.hasOwnProperty(audioId)) {
                const audio = audioInstances[audioId];
                
                // Set volume to the stored temporary volume variable for all audio instances
                fadeVolumeIn(audio, volume);
            }
        }

        // reset the temporary volume variable
        tempVolume = null;

        console.log(`Audio => \Unmuted`);
    }

    // Set volume to a specific value between 0 and 1.
    function setVolume(value) {
        volume = value;
        tempVolume = null;
    }

    // Fade effect for unmuting
    function fadeVolumeIn(audio, volume) {
        const fadeDuration = 500;
        const fadeSteps = 25; // Number of steps in the fade
      
        const volumeStep = volume / fadeSteps;
        let currentVolume = 0;
      
        audio.volume(0); // Start with the volume at 0
      
        const fadeInterval = setInterval(() => {
          currentVolume += volumeStep;
          audio.volume(currentVolume);
      
          if (currentVolume >= volume) {
            audio.volume(volume); // Ensure it's exactly the original volume
            clearInterval(fadeInterval);
          }
        }, fadeDuration / fadeSteps);
      }
    
    // Fade effect for muting
    function fadeVolumeOut(audio) {
        const initialVolume = audio.volume();
        const fadeDuration = 400; 
        const fadeSteps = 25; // Number of steps in the fade
      
        const volumeStep = initialVolume / fadeSteps;
        let currentVolume = initialVolume;
      
        const fadeInterval = setInterval(() => {
            currentVolume -= volumeStep;
            audio.volume(currentVolume);
        
            if (currentVolume <= 0) {
                audio.volume(0); // Ensure it's exactly 0
                clearInterval(fadeInterval);
            }
        }, fadeDuration / fadeSteps);
    }

    function otherAudioListener() {
        // Event listener to stop AudioManager from playing if another source of audio starts playing.
        window.addEventListener("muteAudio", e => {
            store.commit("mute");
        });
        
        
        // Event listener to start AudioManager playing if another source of audio stops playing.
        window.addEventListener("unmuteAudio", e => {
            store.commit("unmute");;
        });
    }

    // Function to determine if audio should be played based on the current route
    function checkIfAudio(route) {
        let scene = scenes.find(scene => scene.route == route.split(":")[0])

        if(!scene)
            return

        // Scene specific audio
        if (scene.components.find(component => component.type === 'sceneAudio')) {
            adjustPlaylist(scene.components.find(component => component.type === "sceneAudio").properties.playlist);

        // Global fallback Audio
        } else if (app.api.PageManager.getCustomComponentFromType('audioManager')) {
            adjustPlaylist(app.api.PageManager.getCustomComponentFromType('audioManager').data.playlist);

        // Clear playlist if no audio should be played
        } else {
            clearPlaylist()
        }
    }

    // Adjust the playlist based on umbraco data from either scene backround- or global component
    function adjustPlaylist(audioList) {
        cleanPlaylist(audioList);
        
        audioList.forEach(audio => {
            if(!playlist.find(playlistAudio => playlistAudio.src.includes(audio.audio.src)))
                addAudioToPlaylist(audio.audio);
        });

        shuffle(playlist);

        // Update currentlyPlayingindex to the relevant index of a shuffled playlist.
        let index = 0;
        for (const audioId in audioInstances) {
            if (audioInstances.hasOwnProperty(audioId)) {
                const audio = audioInstances[audioId];
                
                playlist.forEach((playlistAudio, i) => {

                    if(playlistAudio.src === audio._src) {
                        index = i;
                    }
                })
            }
        }
        
        currentlyPlayingindex = index;

        // Start playing audio from the beginning of the playlist
        startAudio(index);
    }

    // Shuffle playlist
    function shuffle(array) {
        let currentIndex = array.length, randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex > 0) {
      
          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
    }

    // Clean playlist by removing entries not in the umbraco data from either scene background- or global component
    function cleanPlaylist(audioList) {
        playlist = playlist.filter(obj1 => audioList.some(obj2 => obj2.audio.src === obj1.src));
    }
    
    // Clear the entire playlist and all audio instances
    function clearPlaylist() {
        playlist = [];
        clearAllAudioInstances();
        
        console.log(`Audio => \Stopping all playback`);
    }

    // Stop and unload the Howl instance
    function stopAndUnloadAudio(audio) {
        audio.stop();
        audio.unload();
      }
    
    // Clear active audio instances that are not in the playlist
    function clearAudioNotInPlaylist() {
        for (const audioId in audioInstances) {
            if (audioInstances.hasOwnProperty(audioId)) {
                const audio = audioInstances[audioId];
                
                if(!playlist.find(playlistAudio => playlistAudio.src === audio._src)) {
                    stopAndUnloadAudio(audio);
        
                    // Remove the Howl instance from the object
                    delete audioInstances[audioId];
                }
            }
        }
    }
    
    // Clear all audio instances
    function clearAllAudioInstances() {
        for (const audioId in audioInstances) {
            if (audioInstances.hasOwnProperty(audioId)) {
                const audio = audioInstances[audioId];
    
                stopAndUnloadAudio(audio);
    
                // Remove the Howl instance from the object
                delete audioInstances[audioId];
            }
        }
    }
    
    // Clear a specific audio instance by ID
    function clearAudioInstance (id) {
        for (const audioId in audioInstances) {
            if (audioInstances.hasOwnProperty(audioId)) {
                if(id == audioId) {
                    const audio = audioInstances[audioId];
                    
                    stopAndUnloadAudio(audio);
                    
                    // Remove the Howl instance from the object
                    delete audioInstances[audioId];
                }
            }
        }
    }

    
    // Reference an id in audioManager to play a specific audio clip once
    function playSpecificAudio(id) {
        if (app.api.PageManager.getCustomComponentFromType('audioManager')) {
            var audio = app.api.PageManager.getCustomComponentFromType('audioManager').data.miscAudio.find(audio => audio.uniqueID == id)
            if (!audio) {
                console.log(`Audio => Tried to play audio: '${id}' \nCould not find uniqueID:'${id}' in audioManager.miscAudio`);
            }

            playAudioFromSrc(audio.audio.src)
        } else {
            console.log(`Audio => Tried to play audio: '${id}' \nNo audioManager in Global Data`);
        }
    }

    // Use Howler to play a specific audio src
    function playAudioFromSrc(src) {
        const audioId = 'audio_' + src;

        // Create and play a Howl instance (The actual audio object that will be played)
        const player = new Howl({
            src: app.api.Utils.getMediaPath(src),
            volume: volume,
            onend: function() {

                // Clear audio instance when finished
                clearAudioInstance(audioId);
            }
        })

        console.log(`Audio => Playing audio from src: '${src}'`);
        
        // Play the Howl audio
        player.play();   
    }
}