export default function Tracking(app) {
    app.api.Tracking = Object.freeze({
        navigation,
        event
    })

    // Navigation with hooks in the $Router
    async function navigation(category, label, action, ref) {
        const prefix = 'https://www.mitdiabehov.dk/diabeteshuset/hash';
        setTimeout(() => {
            var title = "";
            var contentComponents = app.api.PageManager.getOpenContentComponents()

            if(contentComponents.length === 0) {
                title = app.api.PageManager.getPage(action).title
            } else {
                title = contentComponents[contentComponents.length - 1].title
            }

            var trackingPayload = {
                "url": prefix + action,
                "ref": prefix + ref,
                "title": title
            };
            
            if (Cookiebot.consent.statistics === true) {
                _sz.push(["trackdynamic", trackingPayload]);
            }
        }, 1);
    }
    
    // Explicitly called events with js (buttonclicks etc.)
    function event(category, label, action) {
        let trackingObject = {
            Category: category,
            Label: label,
            Action: action !== undefined && action ? action : ""
        }

        CreateLog(trackingObject)
    }
    
    function CreateLog(data) {
        if (Cookiebot.consent.statistics === true) {   
            let siteimproveobject = ['event', data.Category, data.Label, data.Action]
            _sz.push(siteimproveobject);
        }
    }
}