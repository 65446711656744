<template>
    <!-- Navigation -->
    <PageScrollerNavigation :anchorPoints="computedAnchorPoints" :activeAnchor="activeAnchor" :activeAnchorIndex="activeAnchorIndex" @navigateToAnchor="navigateToAnchor" :state="state"></PageScrollerNavigation>

    <!-- PageScroller Component -->
    <div class="PageScroller" :style="{ transform: 'translateX(' + scrollX + 'px)' }" @pointerdown="handlePointerDown"
        @wheel.passive="handleScrollWheel">
        <slot></slot> 

        <div class="PageScroller__debug_anchor" v-for="anchor in computedAnchorPoints" v-if="showDebugLines && scrollingData.enableDebugging === true" :key="anchor.position" :style="{transform: `translateX(${-anchor.position}px)`}">
            <span>{{ anchor.title }}<br>{{ anchor.position }}px</span>
        </div>
    </div> 

    <!-- Scrollbar -->
    <div class="PageScrollbar" :style="{ width: windowWidth + 'px' }" v-show="scrollbarVisible">
        <div class="handle" :style="{ transform: 'translateX(' + scrollbarPosition + 'px)', width: scrollbarWidth + 'px' }">
        </div>
    </div>

    <!-- Debugger -->
    <div class="PageScroll__debug" :class="{'PageScroll__debug--minimized': !showDebug}" v-if="scrollingData.enableDebugging === true">
        <button @click="showDebug = !showDebug">Sidescroll Debugger</button>
        <h6>{{ state }}</h6>
        <h6>Scroll Position: {{ scrollX.toFixed(1) }}</h6>
        <div>Background Width: {{ backgroundWidth }}</div>
        <div>Anchor Snapping Threshold: {{ anchorSnapThreshold }}px</div>
        <div>Active Anchor: {{ activeAnchor }}</div>
        <div>Active Anchor Index: {{ activeAnchorIndex }}</div>
        <div>Anchor Points:</div>
        <ul>
            <li v-for="anchor in computedAnchorPoints" :key="anchor.position">
                {{ anchor }}
            </li>
        </ul>
        <div>Show Anchor Lines:<input type="checkbox" v-model="showDebugLines"></div>

        <div>Scrollbar Position: {{ scrollbarPosition.toFixed(2) }}px</div>
        <div>Scrollbar Width: {{ scrollbarWidth.toFixed(2) }}px</div>
    </div>
</template>

<script>
import PageScrollerNavigation from './PageScrollerNavigation.vue';

export const scrollState = Object.freeze({
    IDLE: 'Idle',
    STATIONARY: 'Stationary',
    DRAGGING: 'Dragging',
    THROWING: 'Throwing',
    ANIMATING: 'Animating',
});


export default {
    props: ["scrollingData"],
    components: {
        PageScrollerNavigation
    },
    data() {
        return {
            state: scrollState.STATIONARY,
            
            // master scroll 
            scrollX: 0,

            // sizing 
            globalScale: null,
            backgroundWidth: null,
            windowWidth: null,
            scrollMinMax: null,
            initializing: true,


            // scrollbar 
            scrollbarPosition: 0,
            scrollbarWidth: 0,
            scrollbarVisible: false,

            // Only snap to anchor after user has interacted
            hasBeenInteractedWith: false,
            wasInactive: false,
            
            // Delayed hooks
            delayedAnchorsLookup: null,
            idleTimer: null,
            idleTimout: 20000,

            showDebug: true, 
            showDebugLines: true,

            anchorSnapThreshold: 300 
        }
    },
    created() {
        if(this.scrollingData.anchorPointsThreshold !== 0)
            this.anchorSnapThreshold = this.scrollingData.anchorPointsThreshold; 
    },
    mounted() {

        if (!this.scrollingData.enabled)
            return;

        // handle stuff when resizing
        window.addEventListener("backgroundLoaded", this.onResize);

        // handle stuff when resizing
        window.addEventListener("scaleChanged", this.onResize);
        
        // handle undesired behaviour when also clicking outside the PageScroller's scope. (click outside the window and opening rightclick contextmenu)
        window.addEventListener('blur', () => {this.wasInactive = true;});
        document.addEventListener('contextmenu', () => {this.wasInactive = true;});

        // local scroll vars
        let offsetX = 0;
        let clientX = 0;
        let animationFrameOccupied = false;
        let previousScrollX = 0;
        let throwSpeed = 0;
        let throwDamping = .95;


        /// POINTER EVENTS ///
        this.handlePointerDown = (event) => {
            // no scrolling when: 
            // 1) window is wider than background 
            // 2) clicking something other than leftclick
            if (this.scrollMinMax <= 0 || event.button !== 0) {
                return;
            }

            //console.log(event.pointerType, event.clientX);
            event.preventDefault();
            this.$store.commit('toggleDraggingBackground', true)

            // drag offset position
            offsetX = event.clientX / this.globalScale - this.scrollX;

            // start draggin (stop animating)
            this.state = scrollState.DRAGGING;
            this.hasBeenInteractedWith = true;

            const handlePointerMove = (event) => {
                clientX = event.clientX / this.globalScale;
                if (!animationFrameOccupied) {
                    animationFrameOccupied = true;
                    requestAnimationFrame(calcScrollX);
                }
            };

            const handlePointerUp = () => {
                window.removeEventListener('pointermove', handlePointerMove);
                window.removeEventListener('pointerup', handlePointerUp);
                this.$store.commit('toggleDraggingBackground', false);

                // throw!
                startThrowAnimation();
            };

            window.addEventListener('pointermove', handlePointerMove);
            window.addEventListener('pointerup', handlePointerUp);
        };


        /// CALCULATE X WHILE DRAGGING ///
        const calcScrollX = () => {

            // calculate new drag distance
            let dragDelta = clientX - offsetX;
            let clampedDelta = this.clampXToMinMax(dragDelta);
            let overDrag = (dragDelta - clampedDelta) * 0.15;
            this.scrollX = clampedDelta + overDrag;

            // meassuring current speed (if thrown)
            throwSpeed = this.scrollX - previousScrollX;
            if(this.wasInactive) {
                throwSpeed = 0
                this.wasInactive = false 
            }
            previousScrollX = this.scrollX;

            // ready for next drag event
            animationFrameOccupied = false;

            this.updateScrollbar();

        }

        /// CALCULATE X AFTER THROW ///
        const animateThrow = () => {

            if (this.state === scrollState.THROWING) {

                var absoluteThrowSpeed = Math.abs(throwSpeed)
                if (absoluteThrowSpeed > 0.1) {
                    // degrade throwspeed
                    throwSpeed = throwSpeed * throwDamping;
                } else {
                    throwSpeed = 0;
                }

                // Update the scroll position
                this.scrollX += throwSpeed;

                this.updateScrollbar()

                // slow down if border crossed
                if (outOfLimits()) {
                    throwDamping = 0.2;
                }


                if (absoluteThrowSpeed > 0) {
                    // Continue animating until throwspeed is degraded completely
                    requestAnimationFrame(animateThrow);

                } else {
                    // check if limits are crossed
                    var limitsCrossed = outOfLimits();

                    if (limitsCrossed && limitsCrossed != 0) {

                        // duration between 200ms and 400ms depending on how far limit is crossed
                        var durationMs = Math.min(400, Math.max(Math.abs(limitsCrossed) + 200, 200));

                        // go back to limit
                        if (limitsCrossed < 0) {
                            this.animateToX(-this.scrollMinMax, durationMs, this.quadOut);
                        } else {
                            this.animateToX(this.scrollMinMax, durationMs, this.quadOut);
                        }
                    } else {
                        // done throwing - no limits crossed
                        this.state = scrollState.STATIONARY;
                    }
                }
            }
        };

        const startThrowAnimation = () => {
            // reset damping
            throwDamping = 0.95;
            // start the animation if not already animating
            if (this.state != scrollState.THROWING) {
                this.state = scrollState.THROWING
                requestAnimationFrame(animateThrow);
            }
        };

        const outOfLimits = () => {
            if (this.scrollX < -this.scrollMinMax) {
                // start limit crossed
                return (this.scrollX + this.scrollMinMax)
            }
            if (this.scrollX > this.scrollMinMax) {
                // end limit crossed
                return (this.scrollX - this.scrollMinMax);
            }

            return null;
        }

        // other interaction events

        this.handleScrollWheel = (event) => {
            this.state = scrollState.ANIMATING;
            this.scrollX = this.clampXToMinMax(this.scrollX -= event.deltaY);;
            this.updateScrollbar();
            this.state = scrollState.STATIONARY;
        }
    },

    watch: {
        state(val) {
            this.getSizeProps();

            if(val === scrollState.STATIONARY) {
                console.log("start idle timer")
                this.idleTimer = setTimeout(() => {
                    this.state = scrollState.IDLE;
                }, this.idleTimout);
            }
        },
        scrollX(val) { 
            clearTimeout(this.valueChangeTimer);
            clearTimeout(this.idleTimer);

            this.valueChangeTimer = setTimeout(() => {
                if(this.hasBeenInteractedWith === true && this.scrollingData.anchorPoints.length > 0)
                    this.lookupAnchors();
            }, 1000);
        },
    },

    methods: {
        onResize() {
            // refresh size props
            requestAnimationFrame(() => {

                if (this.initializing) {

                    // goto given start position
                    this.goto(this.scrollingData.startPosition, 0, "linear");
                    this.initializing = false;

                } else {
                    // update current scroll position compared to new size
                    var clampedNewX = 0;
                    if (this.scrollMinMax > 0) {
                        var clampedNewX = this.clampXToMinMax(this.scrollX)
                    }
                    this.animateToX(clampedNewX, 0);
                }
            });
        },

        getSizeProps() {
            this.globalScale = this.$store.getters.getScaleValue;
            this.backgroundWidth = this.$store.getters.getBackgroundWidth;
            this.windowWidth = window.innerWidth / this.globalScale;
            this.scrollMinMax = (this.backgroundWidth - this.windowWidth) / 2;
            this.updateScrollbar();
            

            // if still nulls try resize again
            if (!this.globalScale || !this.backgroundWidth) {
                setTimeout(() => {
                    this.onResize();
                }, 200);
            }

        },

        // calculate scrollbar position, size and visiblility
        updateScrollbar() {
            var viewPortPct = this.windowWidth / this.backgroundWidth;
            this.scrollbarPosition = - this.scrollX * viewPortPct;
            this.scrollbarWidth = viewPortPct * this.windowWidth;
            this.scrollbarVisible = (viewPortPct < 1 && this.scrollingData.enabled);
        },

        // GOTO takes any fraction, percentage or position name as strings or floats (fractions)
        // e.g. ("0.33" / "33.3%" / "start","end","center")
        // calculates the x-position and animates towards it 
        goto(position, duration, easing = "easeInOut") {
            if(this.$store.getters.getSavedScrollPosition(app.api.PageManager.getCurrentRoute())) {
                this.animateToX(this.$store.getters.getSavedScrollPosition(app.api.PageManager.getCurrentRoute()).scrollPosition, 0, "linear");
            } else {
                var positionFraction = this.getFractionFromAny(position);
                var positionX = ((positionFraction * 2 - 1) * -this.scrollMinMax)
                this.animateToX(positionX, duration, easing);
            }
        },

        // the basic animator - used directily when the x position is at hand
        animateToX(xPos = 0, duration = 100, easing = "linear") {

            this.state = scrollState.ANIMATING

            const startTime = performance.now();
            const startX = this.scrollX;

            const easingMethod = this.getEasing(easing);

            var scrollTo = xPos

            if(scrollTo > this.scrollMinMax || scrollTo < -this.scrollMinMax)
                if(this.scrollMinMax !== null)
                    scrollTo = Math.sign(xPos) === 1 ? this.scrollMinMax : -this.scrollMinMax 

            const animate = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                if (elapsedTime >= duration) {
                    this.scrollX = scrollTo;
                    this.updateScrollbar();
                    this.state = scrollState.STATIONARY;
                    return;
                }

                const progress = easingMethod(elapsedTime / duration);
                this.scrollX = startX + (scrollTo - startX) * progress;
                this.updateScrollbar();
                
                if (this.state === scrollState.ANIMATING) {
                    requestAnimationFrame(animate);
                }
            };

            requestAnimationFrame((currentTime) => {
                animate(currentTime);
            });
        },

        lookupAnchors() {
            if(!this.activeAnchor)
                return

            let stoppedAt = this.scrollX;
            let closest = this.activeAnchor.position;
            let difference = Math.abs(stoppedAt - closest);
            let animationDuration = difference > 250 ? difference * 4 : 1200;

            if(difference < this.anchorSnapThreshold) 
                this.animateToX(closest, animationDuration, "easeInOut");
        },

        findClosestAnchorpoint(positions, scrollX) {
            let closestPosition = null;
            let minDifference = Infinity;

            for (const position of positions) {
                const difference = Math.abs(position.position - scrollX);

                if (difference < minDifference) {
                    minDifference = difference;
                    closestPosition = position;
                }
            }


            return closestPosition;
        },
        navigateToAnchor(anchor) {
            this.animateToX(anchor.position, 1000, "easeInOut");
        },  

        // goto helpers //

        getFractionFromAny(position) {

            if (typeof position === "string") {
                // strings
                if (position.includes("%")) {
                    // percentage
                    position = position.replace("%", "");
                    return (parseFloat(position / 100))
                } else if (position.startsWith("0") || position.startsWith("1")) {
                    // fraction
                    return (parseFloat(position))
                } else {
                    // name
                    switch (position) {
                        case "start":
                            return 0;
                        case "center":
                            return 0.5;
                        case "end":
                            return 1;
                        default:
                            return 0;
                    }
                }

            } else {
                // int or float
                return position;
            }
        },

        clampXToMinMax(xPos) {
            return (Math.max(-this.scrollMinMax, Math.min(this.scrollMinMax, xPos)));
        },


        // easings (maybe to be placed in a global utillity) //

        getEasing(easingString) {

            switch (easingString) {
                case "quadInOut":
                case "easeInOut":
                default:
                    return this.quadInOut;
                case "quadOut":
                case "easeOut":
                    return this.quadOut;
                case "linear":
                    return this.linear;

            }

        },

        
        quadInOut(progress) {
            progress *= 2;
            if (progress < 1) {
                return 0.5 * progress * progress;
            } else {
                progress -= 1;
                return -0.5 * (progress * (progress - 2) - 1);
            }
        },
        quadOut(progress) {
            return 1 - Math.pow(1 - progress, 2);
        },
        linear(progress) {
            return progress;
        },
    },
    computed: {
        computedAnchorPoints() {
            let arr = [];
            
            arr = this.scrollingData.anchorPoints.map((anchor, i) => {
                anchor.title = anchor.title;
                anchor.position = anchor.position;
                anchor.index = i;
                delete anchor.nsAlias;
                return anchor;
            }) 

            return arr; 
        },
        activeAnchor() {
            if(!this.scrollingData.enabled || this.scrollingData.anchorPoints.length === 0) 
                return null;

                
            let closest = this.findClosestAnchorpoint(this.computedAnchorPoints, this.scrollX);

            if(!closest)
                return null
            
            let difference = Math.abs(this.scrollX - closest.position);
            
            if(difference < this.anchorSnapThreshold) {
                return closest;
            } else {
                return null;
            }
        },
        activeAnchorIndex() {
            if(!this.scrollingData.enabled || this.computedAnchorPoints.length === 0) 
                return null;
            
            return this.activeAnchor ? this.computedAnchorPoints.indexOf(this.activeAnchor) : null;
        }
    },
    unmounted() {
        window.removeEventListener("backgroundLoaded", this.onResize);
        window.removeEventListener("scaleChanged", this.onResize);
        window.removeEventListener('blur', () => {this.wasInactive = true;});
        document.removeEventListener('contextmenu', () => {this.wasInactive = true;});
        
        if(this.scrollingData.enabled) {
            let scrollPositionInfo = {
                scrollPosition: this.scrollX,
                route: app.api.PageManager.getCurrentRoute()
            }
            this.$store.commit('saveScrollPosition', scrollPositionInfo);
        }
    }
};


</script>


<style lang="scss">
.PageScroller {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
    touch-action: auto;
    will-change: transform;
}

.PageScrollbar {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10px;
    bottom: 0px;
    background-color: black;
    z-index: 4;

    .handle {
        position: absolute;
        height: 10px;

        background-color: var(--main-color);
        z-index: 5;
    }
}

.PageScroller__debug_anchor {
    display: flex;
    align-items: center;
    height: 100%;
    background: red;
    width: 1px;
    margin-right: -1px;

    span {
        position: absolute;
        color: red;
        padding-left: 10px;
        white-space: nowrap;
    }
}

.PageScroll__debug {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    text-align: left;
    width: 650px;
    top: 20px;
    font-size: 16px;
    right: 0;
    z-index: 10;
    color: #fff;
    //pointer-events: none !important;
    padding: 20px;

    &>div {
        display: flex;
        margin-bottom: 4px;
    }
    
    h6 {
        font-size: 20px;
        line-height: 26px;
        &:last-of-type {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #000;
        }
    }
    
    ul {
        margin: 6px;
    }

    input[type="checkbox"] {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 18px;
        width: 18px;
        background: #fff;
        color: #000;
        margin-left: 10px;
        cursor: pointer;

        &:checked {
            &::after {
                content: "✓";
                color: red;
                margin-top: -2px;
            }
        }
    }
    
    button {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
        height: 40px;
        pointer-events: all;
        white-space: nowrap;
        padding-left: 20px;
        
        &::after {
            content: '+';
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(45deg);
            font-size: 32px;
        }
    }
    
    &--minimized {
        background: none;
        width: auto;
        
        button {
            background: rgba(0, 0, 0, 0.4);
            &::after {
                transform: rotate(0deg);
            }
        }

        h6, div, ul{
            display: none;
        }
    }
}
</style>