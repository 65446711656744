<template>
    <div class="PageBackground" ref="pageBackground" :style="{ width: getMediaWidth + 'px' }" :class="getBackgroundClasses"
        :data-background-width="getMediaWidth">
        <video :src="backgroundVideo" ref="bgVideo" v-if="hasVideo" @loadedmetadata="measureVideoWidth" muted autoplay loop
            playsinline webkit-playsinline type="video/mp4" @contextmenu.prevent="" disablepictureinpicture></video>
        <img v-if="hasImage" :src="backgroundImage" :alt="background.image.name" @load="backgroundLoaded">
        <div class="top-shade"></div>
    </div>
</template>

<script>
export default {
    props: ["background", "hasContentComponentOpen", "scrollingBackground"],
    data() {
        return {
            videoWidth: 0
        }
    },
    methods: {
        async measureVideoWidth() {
            const videoElement = this.$refs.bgVideo;
            if (videoElement) {
                this.videoWidth = videoElement.videoWidth;
            }
            await this.$nextTick
            this.backgroundLoaded();
        },
        backgroundLoaded() {
            window.dispatchEvent(new Event('backgroundLoaded'));
        }
    },
    computed: {
        hasVideo() {
            return Object.keys(this.background)[0] === 'video'
        },
        getMediaWidth() {
            if (this.hasImage) {
                this.$store.commit('setBackgroundWidth', this.background.image.width)
                return this.background.image.width;
            } else if (this.hasVideo && this.videoWidth > 0) {
                this.$store.commit('setBackgroundWidth', this.videoWidth)
                return this.videoWidth;
            }
        },
        backgroundVideo() {
            if (this.hasVideo) {
                return app.api.Utils.getMediaPath(this.background.video.src)
            } else {
                return null;
            }
        },
        hasImage() {
            return Object.keys(this.background)[0] === 'image'
        },
        backgroundImage() {
            if (this.hasImage) {
                return app.api.Utils.getMediaPath(this.background.image.src);

            } else {
                return null;
            }
        },
        getBackgroundClasses() {
            return {
                'PageBackground--scrollable': this.scrollingBackground,
                'PageBackground--scrolling': this.$store.getters.isBackgroundDragged
            }
        }
    },

};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/_responsiveness.scss';

.PageBackground {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;

    &>* {
        position: absolute;
        width: 100%;
        height: auto;
    }

    &--scrollable {
        cursor: grab;
    }

    &--scrolling {
        cursor: grabbing;
    }

    .top-shade {
        // placed here because the aspect container overflows - zoom-transitions look bad if placed in ui-layer 
        position: sticky;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(36, 63, 71, 1) 0%, rgba(36, 63, 71, 0) 30%);
    }

    @include tablet-down-portrait {
        &>* {
            width: auto;
            height: 100%;
            bottom: 0;
        }
    }
}
</style>