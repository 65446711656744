<template>
    <a :href="content.link" target="_blank" @click="trackNavigation(content.link)">
        {{content.text}}
    </a>
</template>
<script>
export default {
    props: ["content"],
    methods: {
        trackNavigation(link) {
            app.api.Tracking.event("Article", "ExternalLink", link)
        }
    }
}
</script>