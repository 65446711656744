<template>
  <transition name="toggleArticleComponent">
    <article class="Article" v-show="ready">
      <div>
        <button ref="focusMe" class="closebutton" @click="closeComponent" aria-label="luk">
          <img src="@/assets/img/ui/close.png" alt="luk">
        </button>
        <component :is="getContentComponent(content)" v-for="(content, i) in properties.content"
          :key="content.nsAlias + '_' + i" :content="content"></component>
      </div>
    </article>
  </transition>
</template>

<script>
import { markRaw } from 'vue';

export default {
  props: [
    "properties",
    "pageData",
    "slug"
  ],
  data() {
    return {
      ready: false,
      contentComponents: {}
    }
  },
  created() {
    this.importArticleComponents();
  },
  mounted() {
    this.ready = true;

    // set focus to allow arrow scrolling right away
    this.$nextTick(() => {
      this.$refs.focusMe.focus();
    });
  },
  methods: {
    importArticleComponents() {
      // Use require.context to dynamically import components from the ./hotspot-components subfolder
      const componentContext = require.context('./article-components/', true, /\.vue$/);

      // Iterate over each component path and extract the component name
      componentContext.keys().forEach((componentPath) => {
        const componentName = componentPath.replace(/^.\/(.*)\.\w+$/, '$1');

        // Import the component and mark it as non-reactive using markRaw
        this.contentComponents[componentName] = markRaw(componentContext(componentPath).default);
      });
    },
    getContentComponent(content) {
      // Retrieve the corresponding component name from the "type" supplied by the Umbraco model.
      // If the component is not found, use the 'defaultHotspot' component
      const componentName = content.nsAlias;
      const component = this.contentComponents[componentName];

      if (!component) {
        console.log('%c No component found for type: ' + componentName, 'color: red;');
      }

      return component;
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    }
  },
  unmounted() {
  },
  computed: {
  }
};
</script>

<style lang="scss">
@import '@/assets/sass/_responsiveness.scss';

.Article {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: left;
  background: var(--light-grey);
  color: var(--dark-grey);
  // font-size: 26px;
  // line-height: 38px;
  font-size: 1.4rem;
  line-height: 2rem;

  &>div {
    position: relative;
    padding: 100px 150px 75px 150px;
    background: #fff;
    width: 1196px;
    box-shadow: var(--shadow-huge);
    overflow-y: auto;

    &:focus {
      background-color: aqua;
    }
  }

  &>div>a {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    padding: 16px 74px 16px 30px;
    border-radius: 1000px;
    background: var(--light-grey);
    margin-bottom: 60px;

    &::after {
      content: '';
      position: absolute;
      right: 24px;
      width: 26px;
      height: 26px;
      background: url(@/assets/img/ui/external-link-dark.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
    }

    &:focus {
      border: 2px solid var(--main-color);
    }
  }

  @include tablet-down-portrait {
    // font-size: 20px;
    // line-height: 36px;
    font-size: 1.1rem;
    line-height: 2rem;

    &>div {
      padding: 110px 40px 40px 40px;
      width: 100%;
    }
  }

  @include mobile-only-portrait {
    // font-size: 18px;
    // line-height: 27px;
    font-size: 1rem;
    line-height: 1.5rem;

    &>div>a {
      margin-bottom: 35px;
    }
  }
}

.toggleArticleComponent-enter-active {
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-timing-function: var(--tk-easeinout);
}

.toggleArticleComponent-leave-active {
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: var(--tk-easeinout);
}

.toggleArticleComponent-enter-from {
  transform: translateY(120px);
  opacity: 0;

  @include mobile-only-portrait {
    transform: translateY(60px);
  }
}

.toggleArticleComponent-leave-to {
  opacity: 0;
}
</style>