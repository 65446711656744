<template>
    <button id="BurgerButton" aria-label="Menu"></button>
</template>
<style lang="scss" scoped>
@import '@/assets/sass/_responsiveness.scss';

#BurgerButton {
    background-image: url(@/assets/img/ui/burger.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 39px;
    width: 50px;

    @include mobile-only-portrait {
        width: 36px;
        height: 30px;
    }
}

*:focus {
    outline-offset: 20px;
    outline: #fff solid 1px;
}
</style>