<script>
export default {
  render: () => null,
  props: [
    "properties",
    "pageData",
    "slug"
  ],
  mounted() {
    window.open(this.properties.externalUrl, '_blank');
    setTimeout(() => {
      this.$emit("closeComponent", this.slug)
    }, 1);
  },
};
</script>