<template>
    <router-link :to="intenalLinkRoute" @click="trackNavigation">
        {{content.text}}
    </router-link>
</template>
<script>
export default {
    props: ["content"],
    methods: {
        trackNavigation() {
            app.api.Tracking.event("Article", "InternalLink", this.intenalLinkRoute)
        }
    },
    computed: {
        intenalLinkRoute() {
            return app.api.PageManager.getRouteFromIDs(this.content.sceneID, this.content.contentID)
        }
    }
}
</script>